/** @jsx jsx */
import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

const HamburgerBarBase = styled.span`
  display: block;
  height: 3px;
  margin: 10px;
  background-color: var(--text-color);
`;
const FirstHamburgerBar = styled(HamburgerBarBase)<{ isOpen: boolean }>`
  width: 35px;
  transition: transform 0.3s;
  transform: ${({ isOpen }) => isOpen && 'rotate(45deg)'};
`;
const SecondHamburgerBar = styled(HamburgerBarBase)<{ isOpen: boolean }>`
  width: 30px;
  position: relative;
  transition: transform 0.3s, top 0.3s, width 0.3s;
  width: ${({ isOpen }) => isOpen && '35px'};
  transform: ${({ isOpen }) => isOpen && 'rotate(-45deg)'};
  top: ${({ isOpen }) => (isOpen ? '-13px' : '0')};
`;

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const MobileNavButton: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <button
      onClick={() => setIsOpen(!isOpen)}
      css={{
        display: 'none',
        '@media (max-width: 1050px)': {
          display: 'block',
          position: 'fixed',
          top: 0,
          right: 0,
          background: 'none',
          color: 'inherit',
          border: 'none',
          padding: 25,
          font: 'inherit',
          cursor: 'pointer',
          outline: 'inherit',
          zIndex: 20,
        },
      }}
    >
      <FirstHamburgerBar isOpen={isOpen} />
      <SecondHamburgerBar isOpen={isOpen} />
    </button>
  );
};

export default MobileNavButton;
