/** @jsx jsx */
import { Fragment, useState } from 'react';
import { Link } from 'gatsby';
import { jsx } from '@emotion/react';
import { useBreakpointWatcher } from '../hooks/useBreakpointWatcher';
import MobileNav from './MobileNav';
import MobileNavButton from './MobileNavButton';
import DarkModeToggle from './DarkModeToggle';
import { LinkType } from '../types';

const links: LinkType[] = [
  {
    activeClassName: 'activeLink',
    text: 'Home',
    to: '/',
    transitionDuration: 0.5,
  },
  {
    activeClassName: 'activeLink',
    partiallyActive: true,
    text: 'Posts',
    to: '/posts',
    transitionDuration: 0.35,
  },
  {
    activeClassName: 'activeLink',
    partiallyActive: true,
    text: 'Contact',
    to: '/contact',
    transitionDuration: 0.2,
  },
];

const Nav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBreakpointWatcher();

  return (
    <Fragment>
      <MobileNavButton isOpen={isOpen} setIsOpen={setIsOpen} />
      {isMobile ? (
        <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} links={links} />
      ) : (
        <nav
          css={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            '@media (max-width: 1050px)': {
              display: 'none',
            },
          }}
        >
          <ul
            css={{
              all: 'unset',
              fontSize: ' 1.3rem',
              display: 'flex',
              gap: 80,
            }}
          >
            {links.map(({ activeClassName, partiallyActive, text, to }) => (
              <li key={text} css={{ all: 'unset' }}>
                <Link
                  css={{
                    position: 'relative' as 'relative',
                    textDecoration: 'none',
                    color: 'var(--text-color)',
                    fontWeight: 600,
                    letterSpacing: 1,
                    '&::before': {
                      transition: 'width 0.2s ease-out',
                      content: '""',
                      position: 'absolute' as 'absolute',
                      height: 3,
                      width: 0,
                      background: 'var(--text-color)',
                      bottom: -2,
                    },
                    '@media (pointer: fine)': {
                      '&:hover::before': {
                        width: '75%',
                      },
                    },
                  }}
                  activeClassName={activeClassName}
                  partiallyActive={partiallyActive}
                  to={to}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>
          <DarkModeToggle />
        </nav>
      )}
    </Fragment>
  );
};

export default Nav;
