import * as React from 'react';
import { createContext, useState, useContext } from 'react';
import { ColorMode, COLOR_MODE_KEY } from '../constants';

interface IThemeContextProvider {
  colorMode?: ColorMode;
  setColorMode: (colorMode: ColorMode) => void;
}

const ThemeContext = createContext({} as IThemeContextProvider);

const getInitialColorMode = () => {
  if (typeof window !== 'undefined') {
    const colorModeFromLS = localStorage.getItem(COLOR_MODE_KEY);
    if (typeof colorModeFromLS === 'string') {
      return colorModeFromLS as ColorMode;
    }

    const mql = matchMedia('(prefers-color-scheme: dark)');
    if (typeof mql.matches === 'boolean') {
      return mql.matches ? ColorMode.DARK : ColorMode.LIGHT;
    }
  }

  return ColorMode.LIGHT;
};

type Props = {
  children: React.ReactNode;
};

export const ThemeContextProvider: React.FC<Props> = ({ children }) => {
  const [colorMode, rawSetColorMode] = useState(getInitialColorMode());

  const setColorMode = (colorMode: ColorMode) => {
    rawSetColorMode(colorMode);
    localStorage.setItem(COLOR_MODE_KEY, colorMode);

    const style = window.document.documentElement.style;
    if (colorMode === ColorMode.DARK) {
      style.setProperty('--background-color', '#181a20');
      style.setProperty('--text-color', '#fff');
      style.setProperty('--color-mode-toggle-position', 'translateX(15%)');
    }
    if (colorMode === ColorMode.LIGHT) {
      style.setProperty('--background-color', '#fff');
      style.setProperty('--text-color', '#2d3443');
      style.setProperty('--color-mode-toggle-position', 'translateX(150%)');
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        colorMode,
        setColorMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContext;
