/** @jsx jsx */
import { jsx } from '@emotion/react';
import Moon from '../icons/Moon';
import Sun from '../icons/Sun';
import { useThemeContext } from '../contexts/ThemeContext';
import { ColorMode } from '../constants';

const DarkModeToggle = () => {
  const { colorMode, setColorMode } = useThemeContext();

  const handleColorMode = () => {
    if (colorMode === ColorMode.DARK) {
      setColorMode(ColorMode.LIGHT);
    } else {
      setColorMode(ColorMode.DARK);
    }
  };

  return (
    <button
      onClick={handleColorMode}
      css={{
        all: 'unset',
        width: 50,
        height: 25,
        borderRadius: 20,
        backgroundColor: 'var(--soft-black)',
        alignSelf: 'end',
        cursor: 'pointer',
        boxShadow: '2px 4px 6px rgba(0, 0, 0, .4)',
        position: 'relative',
      }}
    >
      <div
        css={{
          position: 'relative',
          zIndex: 5,
          height: 19,
          width: 19,
          backgroundColor: 'var(--white)',
          borderRadius: '50%',
          transition: 'transform .3s ease-in-out',
          transform: 'var(--color-mode-toggle-position)',
        }}
      ></div>
      <Sun
        css={{
          position: 'absolute',
          top: 5,
          left: 5,
          height: 15,
          width: 15,
        }}
      />
      <Moon
        css={{
          position: 'absolute',
          top: 5,
          right: 5,
          height: 15,
          width: 15,
        }}
      />
    </button>
  );
};

export default DarkModeToggle;
