import * as React from 'react';
import { WrapPageElementBrowserArgs } from 'gatsby';

import Layout from '../src/components/Layout';

export const wrapPageElement = ({
  props,
  element,
}: WrapPageElementBrowserArgs) => {
  // Need to know when it's the home page to cascade 100% height
  // for the animated character to show up on the right place
  const isHomePage = props.location.pathname === '/';

  return (
    <Layout {...props} isHomePage={isHomePage}>
      {element}
    </Layout>
  );
};
