/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx, Global } from '@emotion/react';
import DarkModeToggle from './DarkModeToggle';
import { LinkType } from '../types';

type Props = {
  links: LinkType[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const MobileNav: React.FC<Props> = ({ isOpen, links, setIsOpen }) => {
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        opacity: isOpen ? 1 : 0,
        transition: 'opacity .3s ease-in-out, background-color .4s',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'var(--background-color)',
        pointerEvents: isOpen ? 'auto' : 'none',
      }}
    >
      <Global
        styles={{
          body: {
            overflow: isOpen ? 'hidden' : 'initial',
          },
        }}
      />
      <ul
        css={{
          all: 'unset',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: 40,
        }}
      >
        {links.map(({ to, text, transitionDuration }) => (
          <li
            key={text}
            css={{
              all: 'unset',
              transition: `transform ${transitionDuration}s ease-out`,
              transform: isOpen
                ? 'translateX(0%) rotate(0deg)'
                : 'translateX(-100%) rotate(-40deg)',
            }}
          >
            <Link
              css={{
                color: 'var(--text-color)',
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: '1.6rem',
                display: 'block',
                paddingTop: 30,
                paddingBottom: 30,
                WebkitTapHighlightColor: 'transparent',
              }}
              onClick={handleLinkClick}
              to={to}
            >
              {text}
            </Link>
          </li>
        ))}
      </ul>
      <div css={{ position: 'absolute', right: '12%', bottom: '12%' }}>
        <DarkModeToggle />
      </div>
    </nav>
  );
};

export default MobileNav;
