export const IS_MOBILE_WIDTH = 1050;

export const OWNER = 'Samir Ergaibi';
export const EMAIL = 'samirergaibi@gmail.com';

export const LINKS = {
  FACEBOOK: 'https://www.facebook.com/samme.ergaibi',
  LINKED_IN: 'https://www.linkedin.com/in/samir-ergaibi-10151a16a',
  GITHUB: 'https://github.com/samirergaibi',
};

export enum ContentfulTypes {
  ContentfulMarkdown = 'ContentfulMarkdown',
  ContentfulImage = 'ContentfulImage',
}

export enum ColorMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export const COLOR_MODE_KEY = 'color-mode';
