import { useState, useEffect } from 'react';

import { IS_MOBILE_WIDTH } from '../constants';

export const useBreakpointWatcher = (breakpoint: number = IS_MOBILE_WIDTH) => {
  const [isMobile, setIsMobile] = useState(false);

  function handleSetMobile(event: MediaQueryListEvent) {
    if (event.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < breakpoint) {
        setIsMobile(true);
      }

      const mediaQueryListener = window.matchMedia(
        `screen and (max-width: ${breakpoint}px)`
      );
      mediaQueryListener.addEventListener('change', handleSetMobile);
      return () => {
        mediaQueryListener.removeEventListener('change', handleSetMobile);
      };
    }
  }, [handleSetMobile]);

  return isMobile;
};
