/** @jsx jsx */
import { jsx } from '@emotion/react';

type Props = {
  className?: string;
};

const Moon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      width="279"
      height="299"
      viewBox="0 0 279 299"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M179.104 23.8944C201.153 189.919 130.552 244.38 8.40782 214.029C-5.21364 234.54 134.379 329.646 216.864 243.804C298.154 159.206 248.118 35.5992 179.104 23.8944Z"
        fill="#EFEFEF"
      />
    </svg>
  );
};

export default Moon;
