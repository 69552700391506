/** @jsx jsx */
import { Fragment, ReactNode } from 'react';
import Nav from '../components/Nav';
import { jsx } from '@emotion/react';

type Props = {
  isHomePage?: boolean;
  children: ReactNode;
};

const Layout: React.FC<Props> = ({ children, isHomePage }) => {
  return (
    <Fragment>
      <header
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
          width: '100%',
          paddingTop: 50,
        }}
      >
        <Nav />
      </header>
      <main
        css={{
          height: isHomePage ? '100%' : 'auto',
          paddingTop: isHomePage ? '0' : '15vh',
          '@media(max-width: 500px)': {
            fontSize: '1.1rem',
          },
        }}
      >
        {children}
      </main>
    </Fragment>
  );
};

export default Layout;
